import * as React from "react";
import Header from "../../Components/Header/Header";
import HamburgerMenu from "../../Components/HamburgerMenu/HamburgerMenu";
import Privacy from "../../Components/Privacy/Privacy";
import Footer from "../../Components/Footer/Footer";
import Seo from "../../Components/Seo/Seo";

const PrivacyPage = () => {
  return (
    <>
      <Seo locale="it" page="privacy" />
      <Header lang="it" />
      <HamburgerMenu />
      <Privacy lang="it" />
      <Footer lang="it" />
    </>
  );
};

export default PrivacyPage;
